import '../../styles/navigationBar.scss';

import type { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import { memo } from 'react';

import { NAV_BAR_ID } from '../../common/constants';
import logo from '../../images/delonixLogo.svg';

export interface TopNavigationBarProps {
  onToggle: CancelableEventHandler;
  checked: boolean;
}

const TopNavigationBar = (props: TopNavigationBarProps): JSX.Element => {
  return (
    <TopNavigation
      id={NAV_BAR_ID}
      identity={{
        href: '/mainAppAuthorised',
        title: 'Asset Collector',
        logo: {
          src: logo,
          alt: 'Delonix',
        },
      }}
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu',
      }}
      utilities={[
        {
          type: 'button',
          iconSvg: (
            <svg
              className={`custom-icon-toggle-button ${props.checked ? 'selected' : ''}`}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12.8166 9.79921C12.8417 9.75608 12.7942 9.70771 12.7497 9.73041C11.9008 10.164 10.9392 10.4085 9.92054 10.4085C6.48046 10.4085 3.69172 7.61979 3.69172 4.17971C3.69172 3.16099 3.93628 2.19938 4.36989 1.3504C4.39259 1.30596 4.34423 1.25842 4.3011 1.28351C2.44675 2.36242 1.2002 4.37123 1.2002 6.67119C1.2002 10.1113 3.98893 12.9 7.42901 12.9C9.72893 12.9 11.7377 11.6535 12.8166 9.79921Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                className="filled"
              />
            </svg>
          ),
          onClick: props.onToggle,
        },
        {
          type: 'button',
          text: 'Submit your feedback',
          href: 'https://pulse.aws/survey/PB6O2XRU',
          external: true,
        },
        {
          type: 'menu-dropdown',
          text: 'Help',
          items: [
            {
              id: 'slack',
              text: '#asset-collector-interest Slack channel',
              href: 'https://amazon.enterprise.slack.com/archives/C05KSF1GVPA',
              external: true,
            },
            {
              id: 'tt-location',
              text: 'Report missing location',
              href: 'https://tiny.amazon.com/195hkpmn/tcorpamazcreatemp9e35',
              external: true,
            },
            {
              id: 'tt-misc',
              text: 'Report other issue',
              href: 'https://tiny.amazon.com/12xyc6i5u/tcorpamazcreatemp8817',
              external: true,
            },
            {
              id: 'wiki',
              text: 'Wiki',
              href: 'https://support.procore.com/tc/AWS/Learn_By_Topic/AWS_Asset_Collector_App/Asset_Collector_App_SOP',
              external: true,
            },
            {
              id: 'error-guide',
              text: 'Error Guide',
              href: 'https://support.procore.com/tc/AWS/Learn_By_Topic/AWS_Asset_Collector_App/Asset_Collector_App_SOP/Asset_Collector_Error_Guide',
              external: true,
            },
          ],
        },
      ]}
    />
  );
};

export default memo(TopNavigationBar);
